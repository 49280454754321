const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6990953', space_js: '//abpfmzd.hzyizhimei.com/common/to/production/nw/openjs/wuw-l-g.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6990954', space_js: '//abpfmzd.hzyizhimei.com/site/up/ox/x_v/openjs/xml.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6990955', space_js: '//abpfmzd.hzyizhimei.com/common/v_qp/resource/yy_w/ynn.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6990956', space_js: '//abpfmzd.hzyizhimei.com/source/wrq/z/z_xzo/resource/q.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
  Home_inter: INTER,
  Home_banner: BANNER,
  Home_native: NATIVE_1,
  Home_native_center1: NATIVE_2,
  Detail_inter: INTER,
  Detail_banner: BANNER,
  Detail_native: NATIVE_1,
  Detail_native_center1: NATIVE_2,
  Result_inter: INTER,
  Result_banner: BANNER,
  Result_native: NATIVE_1,
  Result_native_center1: NATIVE_2,
};
