import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toHandlerKey as _toHandlerKey, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/guide_new/card_1.png'
import _imports_1 from '@/assets/images/guide_new/card_2.png'
import _imports_2 from '@/assets/images/guide_new/card_3.png'
import _imports_3 from '@/assets/images/guide_new/card_4.png'
import _imports_4 from '@/assets/images/guide_new/finger.png'


import DisclaimerPopVue from "@/components/DisclaimerPop.vue";
import IcpComp from "@/components/IcpComp.vue";

import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5/lib/index";
import { ref, watch } from "vue";
import { useMyStrategy } from "@/hooks/useMyStrategy";
import router from "@/router";

// sdk监听生命周期
enum EventNames {
  Touchstart = "touchstart",
  Click = "click",
}

/** 默认使用touchstart触发点击 */

export default /*@__PURE__*/_defineComponent({
  __name: 'GuideNewView',
  setup(__props) {

Haa.util().useLifeCycle();

let enterTimer: number | null = null;

const clearTimer = () => {
  if (enterTimer != null) {
    clearTimeout(enterTimer);
    enterTimer = null;
  }
};

const { strategy } = useMyStrategy();

const { oeReport, openWindow } = Haa.util().useOeReport();

// 全屏点击
const onFullScreenClick = (e: TouchEvent) => {
  if (strategy.value?.guide_click_enable === 0) return;

  if (strategy.value?.guide_click_enable === 1) {
    onRouterEnter("FullScreen", e);
  }
};

// 是否已触发跳转 网络不好时多次点击会触发多次上报
// const isTriggerJump = ref(false);

// 跳转函数 当前页面所有跳转得经过这个函数
const onRouterEnter = (msgType: string, e?: any) => {
  let appX, appY;

  if (e) {
    appX = e.touches ? e.touches[0].clientX : e.clientX;
    appY = e.touches ? e.touches[0].clientY : e.clientY;
    appX = Math.round(appX);
    appY = Math.round(appY);
  }

  // 避免网络延迟多次点击重复上报
  // if (isTriggerJump.value) return;

  // isTriggerJump.value = true;

  oeReport(
    OeReportType.Action,
    OeReportSubType.BtnClick,
    "Guide",
    msgType,
    undefined,
    appX,
    appY
  );

  onRouterPath();

  clearTimer();
};

const onRouterPath = () => {
  const result = openWindow({ skip: "1" });

  if (result) return;

  router.replace({ path: "/home" });
};

// 31038上报处理函数
const onCriticalClick = (e: TouchEvent) => {
  let appX, appY;
  if (e) {
    appX = e.touches[0].clientX;
    appY = e.touches[0].clientY;
    appX = Math.round(appX);
    appY = Math.round(appY);
  }

  oeReport(
    OeReportType.Action,
    OeReportSubType.NonCriticalAreaClick,
    "Guide",
    "NonCriticalAreaClick",
    undefined,
    appX,
    appY
  );
};

/** 处理策略控制事件处理方式 -----------------begin */
let hotEventName = ref(EventNames.Touchstart);
let notHotEventName = ref(EventNames.Touchstart);

const handleEventName = (type: number) => {
  // 0：全部touch_down触发跳转(默认);
  // 1：热点区域click触发，非热点区域touch_down触发跳转；
  // 2：热点区域touch_down触发，非热点区域click触发跳转;
  // 3：全部click触发跳转；1：热点区域click触发;
  const handleMap = [
    [EventNames.Touchstart, EventNames.Touchstart],
    [EventNames.Click, EventNames.Touchstart],
    [EventNames.Touchstart, EventNames.Click],
    [EventNames.Click, EventNames.Click],
  ];

  const [_hotEventName, _notHotEventName] = handleMap[type] ?? [];

  _hotEventName && (hotEventName.value = _hotEventName);
  _notHotEventName && (notHotEventName.value = _notHotEventName);
};
/** 处理策略控制事件处理方式 -----------------end */

/** 监听策略返回情况 */
watch(
  () => strategy.value,
  (res) => {
    if (!res) return;

    // 根据策略 是否倒计时离开引导页
    if (res.guide_timeout !== -1) {
      enterTimer = setTimeout(() => {
        onRouterEnter("Timerout_" + res.guide_timeout + "s");
      }, res.guide_timeout * 1000);
    }

    // 根据策略 判断是否指定触发事件类型
    handleEventName(res.guide_used_click);
  },
  {
    immediate: true,
  }
);

return (_ctx: any,_cache: any) => {
  const _directive_slide = _resolveDirective("slide")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({ class: "guide-view-wrapper" }, { [_toHandlerKey(_unref(notHotEventName))]: onFullScreenClick }), [
    _createElementVNode("div", _mergeProps({ class: "guide-content" }, {
      [_toHandlerKey(_unref(hotEventName))]: _cache[0] || (_cache[0] = ($event: any) => (onRouterEnter('CardItem', $event)))
    }, {
      onMousedown: _cache[1] || (_cache[1] = ($event: any) => (onRouterEnter('CardItem', $event)))
    }), _cache[2] || (_cache[2] = [
      _createStaticVNode("<img class=\"guide-card animate__animated animate__bounce\" src=\"" + _imports_0 + "\" data-v-ca2fb4c0><img class=\"guide-card animate__animated animate__bounce animate__delay-1s\" src=\"" + _imports_1 + "\" data-v-ca2fb4c0><img class=\"guide-card animate__animated animate__bounce animate__delay-2s\" src=\"" + _imports_2 + "\" data-v-ca2fb4c0><img class=\"guide-card animate__animated animate__bounce animate__delay-3s\" src=\"" + _imports_3 + "\" data-v-ca2fb4c0><img class=\"finger animate__animated animate__heartBeat animate__infinite\" src=\"" + _imports_4 + "\" data-v-ca2fb4c0>", 5)
    ]), 16),
    _createVNode(IcpComp, { class: "c-icp" })
  ], 16)), [
    [_directive_slide]
  ])
}
}

})